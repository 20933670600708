import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { spacing, palette, breakpoints, border, button } = theme;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonBadge = styled.div`
  background: ${palette.secondary.main};
  border-radius: ${button.radius};
  border: ${border.size} solid ${palette.common.black};

  text-transform: uppercase;
  letter-spacing: ${spacing.base * 1.7}px;
  font-size: 0.8rem;

  position: relative;
  padding: ${spacing.base * 1.5}px;
  padding-bottom: ${spacing.base * 1.8}px;

  @media screen and (min-width: ${breakpoints.large}) {
    left: ${spacing.base * 5}px;
    top: ${spacing.base}px;
  }

  padding-left: ${spacing.base * 3.5}px;
`;

export const LogoContainer = styled.div`
  padding-right: ${spacing.base * 4}px;
  svg {
    max-width: 156px;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    padding-right: 0;
    width: 156px;
  }
`;
