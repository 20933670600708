import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { spacing, palette } = theme;

export const StyledHr = styled.hr`
  margin: ${spacing.base * 6}px 0;

  border-bottom: 0;
  border-image-slice: 1;
  border-image-source: ${palette.gradients.holo.main};
`;
