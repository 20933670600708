import { useEffect, useState } from 'react';

import { DownArrowIcon, IlvIcon, UpArrowIcon } from '@/components/icons';
import { fetchIlvPrice, IIlvPrice } from '@/utils/coin-gecko';
import { formatAmount } from '@/utils/helpers';

import { IlvPrice } from './styles';

const DEFAULT_PRICE = {
  usd: 0,
  usdPrice24hChange: 0,
};

export const IlvPriceTicker = (): JSX.Element => {
  const [ilvPrice, setIlvPrice] = useState<IIlvPrice>(DEFAULT_PRICE);

  useEffect(() => {
    const getPrice = async (): Promise<void> => {
      const price = await fetchIlvPrice();
      setIlvPrice(price);
    };
    if (ilvPrice.usdPrice24hChange === 0) {
      getPrice();
    }
  }, [ilvPrice]);

  return (
    <IlvPrice>
      <div className="icon">
        <IlvIcon />
      </div>

      <div className="content">
        <div className="title">ILV price</div>

        <div className="content__price-usd">${formatAmount(ilvPrice.usd)}</div>

        <div
          className={`content__price-change ${
            ilvPrice.usdPrice24hChange >= 0
              ? 'price-change--is-up'
              : 'price-change--is-down'
          }`}
        >
          <>{ilvPrice.usdPrice24hChange >= 0 && <UpArrowIcon />}</>
          <>{ilvPrice.usdPrice24hChange < 0 && <DownArrowIcon />}</>
          <>{ilvPrice.usdPrice24hChange.toFixed(2)}%</>
        </div>
      </div>
    </IlvPrice>
  );
};
