import React from 'react';

import { StyledProps } from '@/types/styled-props';

import { StyledButton } from './styles';

export type Variant = 'primary' | 'secondary';
export type Type = 'submit' | 'button';

export interface ButtonProps {
  type?: Type;
  label?: string;
  variant?: Variant;
  disabled?: boolean;
  onClick?: () => void;
  block?: StyledProps;
  className?: string;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    type = 'button',
    label,
    variant = 'primary',
    disabled,
    onClick,
    className,
    block,
  } = props;

  const handleClick = () => {
    if (!disabled) onClick?.();
  };

  return (
    <StyledButton
      type={type}
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      className={className}
      py={[2, 2, 3, 4]}
      px={[2, 2, 4, 6]}
      {...block}
    >
      {children ?? label}
    </StyledButton>
  );
};

export default Button;
