import { FunctionComponent } from 'react';

import { Container, StyledLoadingSpinner } from './styles';

export const LoadingSpinner: FunctionComponent = () => {
  return (
    <Container>
      <StyledLoadingSpinner>
        <div className="wrapper">
          <div className="main-loader">
            <div className="loader">
              <svg className="circular-loader" viewBox="25 25 50 50">
                <defs>
                  <linearGradient id="linear" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="0%" stopColor="#08CE01" />

                    <stop offset="18%" stopColor="#CEEF00" />

                    <stop offset="35%" stopColor="#51F980" />

                    <stop offset="50%" stopColor="#2D51ED" />

                    <stop offset="67%" stopColor="#F100D9" />

                    <stop offset="83%" stopColor="#9A24EC" />

                    <stop offset="100%" stopColor="#9A24EC" />
                  </linearGradient>
                </defs>

                <circle
                  className="loader-path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  stroke="url(#linear)"
                  strokeWidth="3"
                />
              </svg>
            </div>
          </div>
        </div>
      </StyledLoadingSpinner>
    </Container>
  );
};
