import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { breakpoints, spacing, layout, palette, fonts } = theme;

export const Wrapper = styled.footer`
  hr {
    margin: 0;
  }

  background: linear-gradient(180deg, #130a2a 0%, #1c0b47 100%);

  a {
    text-decoration: none;
  }
`;

export const Container = styled.div`
  padding: ${spacing.base * 8}px;
  height: 100%;
  max-width: ${layout.xLarge};
  margin: 0 auto;

  .logo {
    width: 110px;
  }

  .title {
    color: ${palette.lightBlue.main};
    font-weight: ${fonts.weight.bold};
  }

  .copyright {
    text-align: center;
    margin-top: ${spacing.base * 8}px;
    color: ${palette.lightBlue.main};
    font-size: 0.9rem;
  }

  .row--logo-social {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-bottom: ${spacing.base * 8}px;
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    padding: ${spacing.base * 14}px ${spacing.base * 14}px ${spacing.base * 8}px;

    .logo {
      width: 160px;
    }

    .row {
      display: flex;
      justify-content: space-between;
    }

    .row--site-map-price-exchanges {
      .row__item {
        width: 32%;
      }

      .row__item--site-map {
        width: 24%;
      }

      .row__item--ilv-price {
      }

      .row__item--ilv-price-exchange-list {
        width: 70%;

        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .row--logo-social {
      flex-direction: row;
    }
  }

  @media screen and (min-width: ${breakpoints.large}) {
    padding: ${spacing.base * 10}px ${spacing.base * 6}px ${spacing.base * 6}px;

    .row--site-map-price-exchanges {
      .row__item--ilv-price-exchange-list {
        border-left: 1px solid ${palette.border.main};
        width: 80%;

        display: flex;
        align-items: flex-start;
        justify-content: space-around;
      }
    }
  }
`;

export const SocialIcons = styled.div`
  margin-top: ${spacing.base * 5}px;
  a {
    &:not(:last-child) {
      margin-right: ${spacing.base * 3}px;
    }
    &:hover {
      rect {
        opacity: 0.8;
      }
    }
  }
  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

export const SiteMap = styled.div`
  a,
  div {
    color: ${palette.lightBlue.main};
    font-weight: ${fonts.weight.bold};
  }

  display: flex;

  .site-map__item {
    padding-right: ${spacing.base * 4}px;
  }

  .site-map__label {
    padding-bottom: ${spacing.base * 2}px;
  }

  .active {
    color: ${palette.common.white};
  }

  a {
    display: block;
    &:hover {
      color: ${palette.common.white};
    }
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    justify-content: space-between;
    .site-map__item {
      padding-right: ${spacing.base * 8}px;
    }
  }
`;

export const ExchangeList = styled.div`
  .title {
    margin-bottom: ${spacing.base * 2}px;
  }

  .exchange-list-container {
    display: flex;
    flex-wrap: wrap;
    width: 80%;

    .exchange-list__item {
      padding: ${spacing.base * 1.5}px 0 0;
      margin-right: ${spacing.base * 4}px;
      min-width: 100px;
    }

    a {
      font-size: 1.2rem;
      color: ${palette.common.white};

      display: flex;
      align-items: center;

      svg {
        margin-right: ${spacing.base}px;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.large}) {
    width: 60%;
    .exchange-list-container {
      width: 75%;

      .exchange-list__item {
        padding: ${spacing.base * 1.5}px 0 0;
        &:nth-child(odd) {
          margin-right: ${spacing.base * 6}px;
        }
      }
    }
  }

  &.exchange-list--tablet {
    .title {
      margin-bottom: ${spacing.base * 3}px;
    }

    text-align: center;
    margin: ${spacing.base * 3}px auto;

    .exchange-list-container {
      align-items: center;
      justify-content: center;
      width: 70%;
      margin: 0 auto;

      .exchange-list__item {
        padding: ${spacing.base * 4}px 0 0;
        margin-right: ${spacing.base * 3}px;
      }
    }
  }
`;
