import styled from 'styled-components';

import { theme } from '@/styles/theme';
const { palette } = theme;

export const CopyButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;

  display: flex;
  align-items: center;

  color: ${palette.lightBlue.main};

  cursor: pointer;

  > svg {
    height: 16px;
  }
`;
