import styled, { keyframes } from 'styled-components';

import { HStack, StackProps } from '@/components/stack';
import { theme } from '@/styles/theme';
import { createStyledComponent } from '@/utils/style-factory';

import { ToastProps } from './toast-bar';

const { palette } = theme;
const { gradients } = palette;

const Button = createStyledComponent('button');

const fadeIn = keyframes`
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
`;

export const ToastContainer = styled(HStack)<ToastProps & StackProps>`
  position: relative;
  animation: ${({ visible }) => (visible ? fadeIn : fadeOut)} forwards 500ms;
  user-select: none;
  transition: all 400ms ease-in-out;
  overflow: hidden;

  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
  border: 1px solid transparent;
  color: ${palette.secondary.contrastText};
  background-image: linear-gradient(
      ${palette.secondary.main},
      ${palette.secondary.main}
    ),
    ${({ type }) =>
      type !== 'error'
        ? gradients.holo.full
        : `linear-gradient(
            ${palette.common.red},
            ${palette.common.red}
          )`};
  background-origin: border-box;
  background-clip: padding-box, border-box;

  &:hover {
    &:before {
      animation-play-state: paused;
    }
  }

  &:before {
    content: ${({ duration }) => (duration ? '" "' : 'none')};
    position: absolute;
    inset: 0;
    z-index: -1;
  }
`;

export const DismissButton = styled(Button)`
  transition: all 300ms ease-in-out;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${palette.pink.main}64;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }
`;
