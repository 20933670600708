import { forEach, get, isArray, isObject } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { theme } from '@/styles/theme';
import { BreakpointTree, CssStyle, StyledProps } from '@/types/styled-props';
import { shouldForwardProp } from '@/utils/should-forward-prop';

const { breakpoints, spacing, palette } = theme;

export const toSpace = (val: string | number) =>
  typeof val === 'number' ? `${spacing.base * val}px` : val;

export const toColor = (path?: string) =>
  get<string | undefined>(palette as any, path ?? '', undefined);

export function createStyledComponent<T extends StyledProps>(
  component: keyof JSX.IntrinsicElements | React.ComponentType<any> = 'div'
) {
  return styled(component).withConfig({ shouldForwardProp })<T>((props) => {
    const breakpoints = groupBreakpoints(props);
    return buildResponsiveStyleString(breakpoints, (it) =>
      buildStyleString(it)
    );
  });
}

export function groupBreakpoints<T = string>(style: CssStyle<string, T>) {
  const breakpoints: Required<BreakpointTree<Record<string, T>>> = {
    base: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  };

  const { base, sm, md, lg, xl } = breakpoints;

  forEach(style, (it, key) => {
    if (isArray(it)) {
      const list = it as T[];
      base[key] = list[0];
      sm[key] = list[1];
      md[key] = list[2];
      lg[key] = list[3];
      xl[key] = list[4];
    } else if (isObject(it)) {
      const breakpoint = it as BreakpointTree<any>;
      base[key] = breakpoint.base;
      sm[key] = breakpoint.sm;
      md[key] = breakpoint.md;
      lg[key] = breakpoint.lg;
      xl[key] = breakpoint.xl;
    } else {
      base[key] = it;
    }
  });

  return breakpoints;
}

export function buildResponsiveStyleString<T>(
  breakpointTree: BreakpointTree<Record<string, T>>,
  callback: (breakpoint: Record<string, T>, name: string) => string = (it) =>
    it.toString()
) {
  const breakpointStyled: BreakpointTree<string> = {};

  forEach(breakpointTree, (attr, key) => {
    if (attr) {
      (breakpointStyled as any)[key] = callback(attr, key);
    }
  });

  const { base, sm, md, lg, xl } = breakpointStyled;

  return `
      ${base};
      @media screen and (min-width: ${breakpoints.small}) {
        ${sm};
      }
      @media screen and (min-width: ${breakpoints.medium}) {
        ${md};
      }
      @media screen and (min-width: ${breakpoints.large}) {
        ${lg};
      }
      @media screen and (min-width: ${breakpoints.extraLarge}) {
        ${xl};
      }
  `;
}

export function buildStyleString(rawStyle: Record<string, string>) {
  const { m, my, mx, mt, mb, ml, mr } = rawStyle;
  const { p, py, px, pt, pb, pl, pr } = rawStyle;
  const { flex, justifySelf, alignSelf, order } = rawStyle;
  const { display } = rawStyle;
  const { position } = rawStyle;
  const { inset, insetX, insetY, top, bottom, left, right, zIndex } = rawStyle;
  const {
    borderWidth,
    borderTopWidth,
    borderBottomWidth,
    borderLeftWidth,
    borderRightWidth,
  } = rawStyle;
  const { borderStyle, borderColor } = rawStyle;
  const { width, height, w, h, minW, maxW, minH, maxH } = rawStyle;
  const {
    fontSize,
    fontWeight,
    textTransform,
    textAlign,
    letterSpacing,
    isTruncated,
  } = rawStyle;
  const { shadow, textShadow } = rawStyle;
  const { rounded } = rawStyle;
  const { color, bg } = rawStyle;

  let styleStr = '';

  if (m !== undefined || my !== undefined || mt !== undefined)
    styleStr += `margin-top: ${
      mt !== undefined
        ? toSpace(mt)
        : my !== undefined
        ? toSpace(my)
        : toSpace(m)
    };`;

  if (m !== undefined || my !== undefined || mb !== undefined)
    styleStr += `margin-bottom: ${
      mb !== undefined
        ? toSpace(mb)
        : my !== undefined
        ? toSpace(my)
        : toSpace(m)
    };`;

  if (m !== undefined || mx !== undefined || ml !== undefined)
    styleStr += `margin-left: ${
      ml !== undefined
        ? toSpace(ml)
        : mx !== undefined
        ? toSpace(mx)
        : toSpace(m)
    };`;

  if (m !== undefined || mx !== undefined || mr !== undefined)
    styleStr += `margin-right: ${
      mr !== undefined
        ? toSpace(mr)
        : mx !== undefined
        ? toSpace(mx)
        : toSpace(m)
    };`;

  if (p !== undefined || py !== undefined || pt !== undefined)
    styleStr += `padding-top: ${
      pt !== undefined
        ? toSpace(pt)
        : py !== undefined
        ? toSpace(py)
        : toSpace(p)
    };`;

  if (p !== undefined || py !== undefined || pb !== undefined)
    styleStr += `padding-bottom: ${
      pb !== undefined
        ? toSpace(pb)
        : py !== undefined
        ? toSpace(py)
        : toSpace(p)
    };`;

  if (p !== undefined || px !== undefined || pl !== undefined)
    styleStr += `padding-left: ${
      pl !== undefined
        ? toSpace(pl)
        : px !== undefined
        ? toSpace(px)
        : toSpace(p)
    };`;

  if (p !== undefined || px !== undefined || pr !== undefined)
    styleStr += `padding-right: ${
      pr !== undefined
        ? toSpace(pr)
        : px !== undefined
        ? toSpace(px)
        : toSpace(p)
    };`;

  if (width !== undefined) {
    styleStr += `width: ${width === 'full' ? '100%' : toSpace(width)};`;
  } else if (w !== undefined) {
    styleStr += `width: ${w === 'full' ? '100%' : toSpace(w)};`;
  }

  if (height !== undefined) {
    styleStr += `height: ${height === 'full' ? '100%' : toSpace(height)};`;
  } else if (h !== undefined) {
    styleStr += `height: ${h === 'full' ? '100%' : toSpace(h)};`;
  }

  if (minW !== undefined) {
    styleStr += `min-width: ${minW === 'full' ? '100%' : toSpace(minW)};`;
  }

  if (maxW !== undefined) {
    styleStr += `max-width: ${maxW === 'full' ? '100%' : toSpace(maxW)};`;
  }

  if (minH !== undefined) {
    styleStr += `min-height: ${minH === 'full' ? '100%' : toSpace(minH)};`;
  }

  if (maxH !== undefined) {
    styleStr += `max-height: ${maxH === 'full' ? '100%' : toSpace(maxH)};`;
  }

  if (flex !== undefined) {
    styleStr += `flex: ${flex};`;
  }

  if (justifySelf !== undefined) {
    styleStr += `justify-self: ${justifySelf};`;
  }

  if (alignSelf !== undefined) {
    styleStr += `align-self: ${alignSelf};`;
  }

  if (order !== undefined) {
    styleStr += `order: ${order};`;
  }

  if (display !== undefined) {
    styleStr += `display: ${display};`;
  }

  if (position !== undefined) {
    styleStr += `position: ${position};`;
  }

  if (inset !== undefined || insetY !== undefined || top !== undefined) {
    styleStr +=
      top !== undefined
        ? `top: ${toSpace(top)};`
        : insetY !== undefined
        ? `top: ${toSpace(insetY)}; bottom: ${toSpace(insetY)};`
        : `top: ${toSpace(inset)}; bottom: ${toSpace(inset)}; left: ${toSpace(
            inset
          )}; right: ${toSpace(inset)};`;
  }

  if (inset !== undefined || insetY !== undefined || bottom !== undefined) {
    styleStr +=
      bottom !== undefined
        ? `bottom: ${toSpace(bottom)};`
        : insetY !== undefined
        ? `top: ${toSpace(insetY)}; bottom: ${toSpace(insetY)};`
        : `top: ${toSpace(inset)}; bottom: ${toSpace(inset)}; left: ${toSpace(
            inset
          )}; right: ${toSpace(inset)};`;
  }

  if (inset !== undefined || insetX !== undefined || left !== undefined) {
    styleStr +=
      left !== undefined
        ? `left: ${toSpace(left)};`
        : insetX !== undefined
        ? `left: ${toSpace(insetX)}; right: ${toSpace(insetX)};`
        : `top: ${toSpace(inset)}; bottom: ${toSpace(inset)}; left: ${toSpace(
            inset
          )}; right: ${toSpace(inset)};`;
  }

  if (inset !== undefined || insetX !== undefined || right !== undefined) {
    styleStr +=
      right !== undefined
        ? `right: ${toSpace(right)};`
        : insetX !== undefined
        ? `left: ${toSpace(insetX)}; right: ${toSpace(insetX)};`
        : `top: ${toSpace(inset)}; bottom: ${toSpace(inset)}; left: ${toSpace(
            inset
          )}; right: ${toSpace(inset)};`;
  }

  if (zIndex !== undefined) {
    styleStr += `z-index: ${zIndex};`;
  }

  if (fontSize !== undefined) styleStr += `font-size: ${toSpace(fontSize)};`;
  if (fontWeight !== undefined) styleStr += `font-weight: ${fontWeight};`;
  if (textTransform !== undefined)
    styleStr += `text-transform: ${textTransform};`;
  if (textAlign !== undefined) styleStr += `text-align: ${textAlign};`;
  if (letterSpacing !== undefined)
    styleStr += `letter-spacing: ${toSpace(letterSpacing)};`;
  if (isTruncated !== undefined) {
    styleStr += `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }

  if (rounded !== undefined)
    styleStr += `border-radius: ${
      rounded === 'full' ? '9999px' : toSpace(rounded)
    };`;

  if (shadow !== undefined)
    styleStr += `box-shadow: 0 1px ${shadow}px 0 rgba(255, 255, 255, 0.16);`;

  if (textShadow !== undefined)
    styleStr += `text-shadow: 0 2px ${textShadow}px rgba(0, 0, 0, 0.4);`;

  if (borderWidth !== undefined || borderTopWidth !== undefined)
    styleStr += `border-top-width: ${
      borderTopWidth ? `${borderTopWidth}px` : `${borderWidth}px`
    };`;

  if (borderWidth !== undefined || borderBottomWidth !== undefined)
    styleStr += `border-bottom-width: ${
      borderBottomWidth ? `${borderBottomWidth}px` : `${borderWidth}px`
    };`;

  if (borderWidth !== undefined || borderLeftWidth !== undefined)
    styleStr += `border-left-width: ${
      borderLeftWidth ? `${borderLeftWidth}px` : `${borderWidth}px`
    };`;

  if (borderWidth !== undefined || borderRightWidth !== undefined)
    styleStr += `border-right-width: ${
      borderRightWidth ? `${borderRightWidth}px` : `${borderWidth}px`
    };`;

  if (borderStyle !== undefined) styleStr += `border-style: ${borderStyle};`;
  if (borderColor !== undefined)
    styleStr += `border-color: ${toColor(borderColor)};`;

  if (bg !== undefined) styleStr += `background-color: ${toColor(bg)};`;
  if (color !== undefined) styleStr += `color: ${toColor(color)};`;

  return styleStr;
}
