import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { spacing, palette, nav } = theme;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 100%;

  &:hover .dropdown-menu-container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

export const StyledLink = styled.a`
  padding: 0 ${spacing.base * 2}px;
  margin: 0 ${spacing.base * 2}px;

  color: ${palette.lightBlue.main};
  text-decoration: none;

  &:hover {
    color: ${palette.common.white};
  }

  &.active {
    color: ${palette.common.white};
  }
  cursor: pointer;
`;

export const DropdownToggle = styled.button`
  padding: 0;
  background: none;
  border: 0;

  margin-top: ${spacing.base * 4.5}px;

  color: ${palette.lightBlue.main};

  &:hover {
    cursor: pointer;
  }

  &:after {
    display: block;
    text-align: center;
    position: relative;
    content: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.046285L9.98993 1.34483e-06C9.94628 0.028261 9.90095 0.0531771 9.85814 0.084552C8.96468 0.736972 8.01514 1.21672 7.04781 1.63349C5.81556 2.1642 4.56485 2.22273 3.31531 1.76768C2.23612 1.3752 1.19262 0.827209 0.203472 0.133507C0.141859 0.0905613 0.0899834 0.0046683 -8.26069e-07 0.0295902C0.00870127 0.0515889 0.0196628 0.071886 0.032569 0.0898956C1.58883 1.67377 3.14508 3.25787 4.70134 4.84242C4.92059 5.06671 5.09771 5.04379 5.31192 4.82305C6.87322 3.22538 8.43602 1.63328 10 0.046285Z' fill='%237474AA'/%3E%3C/svg%3E%0A");
  }
`;

export const DropdownMenu = styled.div`
  position: fixed;
  top: ${nav.height};

  opacity: 0;
  visibility: hidden;

  transition: all 0.4s;
  height: 0;

  ul.dropdown-menu {
    background-color: ${palette.inputBg.main};
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-bottom: ${spacing.base * 5}px;

    min-width: 120px;
    text-align: center;

    width: 160px;

    li {
      padding: 1em;
    }
  }
`;
