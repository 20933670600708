import styled from 'styled-components';

import { theme } from '@/styles/theme';
import { createStyledComponent } from '@/utils/style-factory';

const Button = createStyledComponent('button');

const { palette } = theme;

export const FavoriteButtonContainer = styled(Button)`
  transition: all 300ms ease-in-out;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${palette.pink.main}64;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }
`;
