import { cloneDeep, isArray, isObject } from 'lodash';
import React from 'react';

import { BoxProps } from '@/components/box/box';
import { StackBox } from '@/components/stack/styles';
import { StyledValue } from '@/types/styled-props';

export interface StackProps extends BoxProps {
  spacing?: StyledValue<number | string>;
  alignItems?: StyledValue<'center' | 'flex-start' | 'flex-end' | string>;
  justifyContent?: StyledValue<'center' | 'flex-start' | 'flex-end' | string>;
  direction?: StyledValue<
    'row' | 'column' | 'row-reverse' | 'column-reverse' | string
  >;
}

/**
 * Stack helps to easily handle flex system
 */
export const Stack: React.FC<StackProps> = (props) => {
  const { direction = 'column', spacing = 2, ...rest } = props;

  const directionComputed = React.useMemo(() => {
    const result = cloneDeep(direction);

    if (isArray(result)) {
      const { length } = result;
      for (let i = length; i <= 5; i++) {
        result.push(result[length - 1]);
      }
      return result;
    } else if (isObject(result)) {
      result.xl =
        result.xl ?? result.lg ?? result.md ?? result.sm ?? result.base;

      result.lg = result.lg ?? result.md ?? result.sm ?? result.base;

      result.md = result.md ?? result.sm ?? result.base;

      result.sm = result.sm ?? result.base;

      return result;
    }

    return new Array(5).fill(direction);
  }, [direction]);

  return <StackBox direction={directionComputed} spacing={spacing} {...rest} />;
};

/**
 * Horizontal Stack
 */
export const HStack: React.FC<StackProps> = (props) => {
  const {
    direction = 'row',
    alignItems = 'center',
    spacing = 2,
    ...rest
  } = props;

  return (
    <StackBox
      direction={direction}
      alignItems={alignItems}
      spacing={spacing}
      {...rest}
    />
  );
};

/**
 * Vertical Stack
 */
export const VStack: React.FC<StackProps> = (props) => {
  const {
    direction = 'column',
    alignItems = 'center',
    spacing = 2,
    ...rest
  } = props;

  return (
    <StackBox
      direction={direction}
      alignItems={alignItems}
      spacing={spacing}
      {...rest}
    />
  );
};
