export type ThemeType = typeof theme;

export const breakpointsValues = {
  sm: 375,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const theme = {
  border: {
    size: '1px',
    radius: '8px',
  },
  button: {
    radius: '4px',
  },
  fonts: {
    weight: {
      bold: '700',
      normal: '400',
    },
    lineHeight: 1.5,
  },
  spacing: {
    base: 4,
  },
  nav: {
    height: '80px',
    maxWidth: '1200px',
    zIndex: 99,
  },
  walletFooter: {
    height: '64px',
  },
  currency: {
    decimals: 2,
    minimumAmount: 0.0001,
  },
  layout: {
    small: '800px',
    large: '1000px',
    xLarge: '1200px',
  },
  modal: {
    zIndex: 100,
    minWidth: '500px',
  },
  notifications: {
    zIndex: 101,
  },
  breakpoints: {
    small: `${breakpointsValues.sm}px`,
    medium: `${breakpointsValues.md}px`,
    large: `${breakpointsValues.lg}px`,
    extraLarge: `${breakpointsValues.xl}px`,
  },
  mediaQueries: {
    sm: `screen and (min-width:  ${breakpointsValues.sm}px)`,
    md: `screen and (min-width:  ${breakpointsValues.md}px)`,
    lg: `screen and (min-width:  ${breakpointsValues.lg}px)`,
    xl: `screen and (min-width:  ${breakpointsValues.xl}px)`,
  },
  devices: {
    desktop: `(min-width: ${breakpointsValues.lg}px)`,
    tablet: `(min-width: ${breakpointsValues.md}px) and (max-width: ${
      breakpointsValues.lg - 1
    }px)`,
    mobile: `(max-width: ${breakpointsValues.md - 1}px)`,
  },
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
      grey: '#888791',
      red: '#af393f',
    },
    gradients: {
      primary: {
        main: 'linear-gradient(180deg, #520990 0%, #3C0769 100%)',
        hover: 'linear-gradient(180deg, #680cb6 0%, #4d0986 100%)',
        contrastText: '#ffffff',
      },
      secondary: {
        main: 'linear-gradient(180deg, #1F1B41 0%, #110F24 100%)',
        hover: 'linear-gradient(180deg, #1F1B41 0%, #14122B 100%)',
        contrastText: '#ffffff',
      },
      holo: {
        hover: '',
        main: 'linear-gradient(270deg, rgba(9, 228, 97, 0) 0%, #ceef00 17.19%, #51f980 34.38%, #2d51ed 50%, #0060f1 67.19%, #f100d9 83.33%, rgba(45, 73, 85, 0) 99.75%)',
        full: 'linear-gradient(270deg, #08CE01 0%, #CEEF00 17.19%, #51F980 34.38%, #2D51ED 50%, #0060F1 67.19%, #F100D9 83.33%, #9A24EC 100%)',
        border:
          'linear-gradient(rgb(24, 21, 50), rgb(24, 21, 50)), linear-gradient(270deg, rgb(8, 206, 1) 0%, rgb(206, 239, 0) 17.19%, rgb(81, 249, 128) 34.38%, rgb(45, 81, 237) 50%, rgb(0, 96, 241) 67.19%, rgb(241, 0, 217) 83.33%, rgb(154, 36, 236) 100%)',
        contrastText: '#ffffff',
      },
    },
    primary: {
      main: '#520990',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#181532',
      contrastText: '#ffffff',
    },
    purple: {
      main: '#AB54F4',
      contrastText: '#ffffff',
    },
    pink: {
      main: '#D0B5DB',
      contrastText: '#ffffff',
    },
    green: {
      main: '#4ce8be',
      contrastText: '#000000',
    },
    brightGreen: {
      main: '#5EDB4A',
      contrastText: '#ffffff',
    },
    lightBlue: {
      main: '#7474AA',
      contrastText: '#ffffff',
    },
    bodyBg: {
      main: '#130A2A',
      contrastText: '#ffffff',
    },
    containerBg: {
      main: '#110F24',
      contrastText: '#ffffff',
    },
    inputBg: {
      main: '#0E0C1D',
      contrastText: '#ffffff',
    },
    cinder: {
      main: '#07070D',
      contrastText: '#ffffff',
    },
    border: {
      main: '#25214F',
      contrastText: '#ffffff',
    },
    price: {
      green: '#5EDB4A',
      red: '#DB4A4A',
    },
  },
};
