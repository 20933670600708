import React, { ReactNode } from 'react';

import { theme } from '@/styles/theme';

import { Container } from './styles';

const { palette } = theme;

export interface IHighlightText {
  children: ReactNode;
  color: string;
}

export const HighlightText: React.FC<IHighlightText> = ({
  children,
  color = palette.lightBlue.main,
}) => {
  return <Container color={color}>{children}</Container>;
};
