import styled from 'styled-components';

import {
  buildResponsiveStyleString,
  groupBreakpoints,
  toSpace,
} from '@/utils/style-factory';

import { Box } from '../box/box';
import { StackProps } from './stack';

export const StackBox = styled(Box)<StackProps>((props) => {
  const breakpoints = groupBreakpoints<any>(props);

  return buildResponsiveStyleString(breakpoints, (breakpoint) => {
    const { spacing, direction, alignItems, justifyContent } = breakpoint;

    let styleStr = 'display: flex;';

    if (alignItems !== undefined) styleStr += `align-items: ${alignItems};`;

    if (justifyContent !== undefined)
      styleStr += `justify-content: ${justifyContent};`;

    if (direction !== undefined) styleStr += `flex-direction: ${direction};`;

    if (spacing !== undefined) {
      if (direction === 'row' || direction === 'row-reverse') {
        styleStr += `
          & > *:not(style) ~ *:not(style) {
            margin-top: 0px;
            margin-inline: ${toSpace(spacing)} 0px;
            margin-bottom: 0px;
          }
        `;
      } else {
        styleStr += `
          & > *:not(style) ~ *:not(style) {
            margin-top: ${toSpace(spacing)};
            margin-inline-end: 0px;
            margin-bottom: 0px;
            margin-inline-start: 0px;
          }
        `;
      }
    }

    return styleStr;
  });
});
