import styled from 'styled-components';

import { StyledButton } from '@/components/button/styles';
import { HStack } from '@/components/stack';
import { theme } from '@/styles/theme';

const { palette, spacing } = theme;

export const HeaderStack = styled(HStack)`
  background: ${palette.inputBg.main};
  padding: ${spacing.base * 2}px;
  margin: 0 0 ${spacing.base * 8}px;
  display: flex;

  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabButton = styled(StyledButton)`
  transition: all 300ms ease-in-out;
  font-size: 0.8rem;
  justify-content: center;

  border: none;
  background: transparent;
  color: ${palette.common.grey};

  &:hover {
    background: ${palette.pink.main}10;
  }

  &.selected {
    color: ${palette.inputBg.contrastText};
    border: 1px solid transparent;
    border-radius: 8px;
    background-image: linear-gradient(
        ${palette.secondary.main},
        ${palette.secondary.main}
      ),
      linear-gradient(#2d275d 0%, #1c1839 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
`;

export const ActiveTab = styled.div``;
