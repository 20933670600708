import styled, { createGlobalStyle } from 'styled-components';

import { theme } from '@/styles/theme';

const { palette, modal, breakpoints, spacing } = theme;

export const DisablePageScroll = createGlobalStyle`
  body {
    height: 100vh;
    overflow-y: hidden;
  }
  html, body {
    touch-action: none;
  }
`;

export const StyledFullScreenModal = styled.div`
  background: ${palette.containerBg.main};
  touch-action: initial;
`;

interface StyledModalProps {
  width?: string;
}

export const StyledModal = styled.div<StyledModalProps>`
  touch-action: initial;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(19, 10, 42, 0.8);

  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  overflow-y: auto;
  z-index: ${modal.zIndex};

  .modal-container {
    margin: 0 auto;
    width: 100%;

    background: ${palette.containerBg.main};
    padding: ${spacing.base * 8}px;

    @media screen and (min-width: ${breakpoints.medium}) {
      width: ${(props) => props.width || modal.minWidth};
    }
  }
`;

export const ModalButton = styled.button`
  width: 100%;
`;
