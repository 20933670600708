import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { spacing, palette, nav, breakpoints } = theme;

export const StyledNav = styled.nav`
  background-color: ${palette.inputBg.main};
  position: sticky;
  top: 0;

  width: 100%;
  height: ${nav.height};

  z-index: ${nav.zIndex};
`;

export const NavContainer = styled.div`
  display: flex;

  height: 100%;

  > div {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    padding: 0 ${spacing.base * 6}px;
    height: 100%;
    max-width: ${nav.maxWidth};
  }
`;

// Container for staking-app's Nav
export const SpaceAround = styled.div`
  display: flex;
  justify-content: space-around;
`;

// Container for staking-app's Nav
export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

// Container for Actions in the right side of IlluviDex's Nav
export const ActionsContainer = styled.div`
  display: none;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  min-width: 500px;

  @media screen and (min-width: ${breakpoints.medium}) {
    display: flex;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    display: flex;
  }
`;

export const DisconnectButton = styled.span`
  font-size: 16px;
`;
