import styled from 'styled-components';

import { theme } from '@/styles/theme';
import { createStyledComponent } from '@/utils/style-factory';

import { buttonStyles, secondaryButtonStyles } from '../button/styles';

const { palette, button, border, spacing, nav } = theme;
const { gradients } = palette;

const Button = createStyledComponent('button');

export const Container = styled.button`
  border-radius: ${button.radius};
  border: ${border.size} solid ${palette.common.black};
  padding: ${spacing.base}px;

  background: ${gradients.secondary.main};
  color: ${gradients.secondary.contrastText};
  &:hover {
    background: ${gradients.secondary.hover};
  }
  display: flex;
  align-items: center;

  height: 46px;
  max-width: 170px;
  font-size: 0.8rem;
  flex-grow: 0;

  img {
    object-fit: cover;
    width: 24px;
    height: 24px;

    border-radius: 50px;
  }

  cursor: pointer;

  &:hover .dropdown-menu-container {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
`;

export const CircularHoloBorder = styled.div`
  display: flex;
  padding: 1px;
  margin: 6px;

  border-radius: 50px;
  background-image: ${palette.gradients.holo.full};
  background-origin: border-box;
  background-clip: padding-box, border-box;
`;

export const HoloBorder = styled.div`
  padding: 4px 8px 8px 8px;

  border: 1px solid transparent;
  border-radius: ${button.radius};
  background-image: linear-gradient(
      ${palette.secondary.main},
      ${palette.secondary.main}
    ),
    ${palette.gradients.holo.full};
  background-origin: border-box;
  background-clip: padding-box, border-box;

  font-size: 16px;
  letter-spacing: 0.1px;

  font-weight: normal;
`;

export const NotConnected = styled(Button)`
  ${buttonStyles}
  ${secondaryButtonStyles}
  display: flex;
  align-items: center;

  height: 46px;
  max-width: 208px;
  font-size: 0.8rem;
  flex-grow: 0;
`;

export const DropdownMenu = styled.div`
  position: fixed;
  top: ${nav.height};

  opacity: 0;
  visibility: hidden;

  width: 120px;
  height: 0;

  transition: all 0.4s;

  ul.dropdown-menu {
    background-color: ${palette.inputBg.main};
    padding-bottom: ${spacing.base * 5}px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      padding: 1em;
    }
  }
`;

export const StyledLink = styled.a`
  padding: 0 ${spacing.base * 2}px;
  margin: 0 ${spacing.base * 2}px;

  color: ${palette.lightBlue.main};
  text-decoration: none;

  &:hover {
    color: ${palette.common.white};
  }

  &.active {
    color: ${palette.common.white};
  }
  cursor: pointer;
`;
