import styled, { css } from 'styled-components';

import { theme } from '@/styles/theme';

const { spacing, palette } = theme;

const messageStyles = (message: string) => css`
  min-width: 60px;
  position: relative;
  display: flex;
  cursor: pointer;

  &:hover {
    &:after {
      display: flex;
      bottom: -1.5em;
      position: absolute;
      color: ${palette.common.white};
      content: '${message}';
      font-size: 0.625rem;
      transition: opacity 0.3s ease-in-out;
    }
  }
`;

interface IStyledLink {
  message?: string;
}

export const StyledLink = styled.a<IStyledLink>`
  padding: 0 2px;
  padding-left: ${spacing.base * 2}px;
  color: ${palette.lightBlue.main};
  text-decoration: none;
  &:hover {
    color: ${palette.common.white};
  }
  &.active {
    color: ${palette.common.white};
  }
  ${(props) => (props.message ? messageStyles(props.message) : null)}
`;
