import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { palette, nav, spacing } = theme;

export const StyledNavMobile = styled.nav`
  background-color: ${palette.inputBg.main};

  position: fixed;
  top: ${nav.height};
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: ${spacing.base * 8}px;
`;

export const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
`;
