import { theme } from '@/styles/theme';
const { breakpoints } = theme;

export const { format: toUSD } = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const { format: formatAmount } = new Intl.NumberFormat('en-US', {
  currency: 'USD',
});

export const toCurrency = (
  input: number,
  currency = 'USD',
  locale = 'en-US'
) => {
  const { format } = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return format(input);
};

export const toDecimal = (input: number, locale = 'en-US') => {
  const { format } = new Intl.NumberFormat(locale);
  return format(input);
};

export const MEDIUM_BP = {
  query: `(min-width: ${breakpoints.medium})`,
};

export const LARGE_BP = {
  query: `(min-width: ${breakpoints.large})`,
};

export const truncateAddress = (address: string): string => {
  if (address) {
    const first = address.substring(0, 6);
    const last = address.substring(38, 42);
    return `${first}...${last}`;
  } else {
    return address;
  }
};
