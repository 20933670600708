import styled from 'styled-components';

import type { AspectRatioProps } from '@/components/aspect-ratio/aspect-ratio';
import { Box } from '@/components/box';

export const AspectRatioBox = styled(Box)<AspectRatioProps>`
  position: relative;

  &:before {
    content: ' ';
    height: 0;
    display: block;
    padding-bottom: ${({ ratio }) => (1 / ratio) * 100 + '%'};
  }

  & > :not(style) {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
`;
