import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { palette, nav, spacing, border } = theme;

export const StyledNavMobile = styled.nav`
  background-color: ${palette.inputBg.main};

  position: fixed;
  top: ${nav.height};
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: ${spacing.base * 8}px;
`;

export const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 1.2rem;
`;

export const StyledLink = styled.a`
  color: ${palette.lightBlue.main};
  text-decoration: none;
  padding: ${spacing.base * 4}px 0;
  border-bottom: ${border.size} solid ${palette.border.main};
  &:last-child {
    border-bottom: none;
  }

  &.active {
    color: ${palette.common.white};
  }
`;

export const NavDropdown = styled.div`
  color: ${palette.lightBlue.main};
  text-decoration: none;

  border-bottom: ${border.size} solid ${palette.border.main};
  &:last-child {
    border-bottom: none;
  }

  &.active {
    color: ${palette.common.white};
  }
`;

export const DropdownButton = styled.button`
  border: none;
  padding: 0;

  background-color: ${palette.inputBg.main};
  color: ${palette.lightBlue.main};
  width: 100%;
  min-height: 55px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: ${border.size} solid ${palette.border.main};
`;

export const DropdownMenu = styled.div`
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    padding: ${spacing.base * 5}px ${spacing.base * 4}px;
  }

  li:not(:last-child) {
    padding-bottom: 16px;
  }
`;
