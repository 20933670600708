import styled from 'styled-components';

import { theme } from '@/styles/theme';
import { createStyledComponent } from '@/utils/style-factory';

import { buttonStyles, secondaryButtonStyles } from '../button/styles';

const { palette, button, border, spacing } = theme;
const { gradients } = palette;

const Button = createStyledComponent('button');

export const Container = styled.button`
  text-transform: lowercase;
  border-radius: ${button.radius};
  border: ${border.size} solid ${palette.common.black};
  padding: ${spacing.base}px;

  background: ${gradients.secondary.main};
  color: ${gradients.secondary.contrastText};
  &:hover {
    background: ${gradients.secondary.hover};
  }
  display: flex;
  align-items: center;

  height: 46px;

  font-size: 0.8rem;
  flex-grow: 0;

  img {
    object-fit: cover;
    width: 24px;
    height: 24px;

    border-radius: 50px;
  }
`;

export const HoloBorder = styled.div`
  padding: 4px 8px 8px 8px;

  border: 1px solid transparent;
  border-radius: ${button.radius};
  background-image: linear-gradient(
      ${palette.secondary.main},
      ${palette.secondary.main}
    ),
    ${palette.gradients.holo.full};
  background-origin: border-box;
  background-clip: padding-box, border-box;

  font-size: 16px;
  letter-spacing: 0.1px;

  font-weight: normal;
`;

export const NotConnected = styled(Button)`
  ${buttonStyles}
  ${secondaryButtonStyles}
  display: flex;
  align-items: center;

  height: 46px;
  max-width: 208px;
  font-size: 0.8rem;
  flex-grow: 0;
`;
