import styled from 'styled-components';

import { Box, BoxProps } from '@/components/box/box';
import { NftCardProps } from '@/components/nft-card/nft-card';
import { Stack } from '@/components/stack/stack';
import { theme } from '@/styles/theme';
import { createStyledComponent, toColor, toSpace } from '@/utils/style-factory';

const { palette } = theme;

export const Image = createStyledComponent('img');

export const NftCardAnchor = styled(Box)<NftCardProps>`
  ${({ animated }) => animated && `transition: all 300ms ease-in-out;`}

  display: block;
  outline: none;
  overflow: hidden;

  border-style: solid;
  border-width: 1px;
  border-color: ${palette.common.black};

  padding: 1px;
  border-radius: 9px;
  box-shadow: 0 3px 5px 1px hsl(0deg 0% 0% / 40%);

  ${({ themeColor, isHolo }) => `
    background: linear-gradient(
      ${
        isHolo
          ? `${palette.common.black}00`
          : themeColor
          ? toColor(themeColor)
          : palette.common.grey
      } 0%,
      ${palette.common.black} 100%
    )${isHolo ? `, ${palette.gradients.holo.full}` : ''};
  `}

  ${({ canHover, forceHover, animated }) =>
    (canHover || forceHover) &&
    `
      ${forceHover === true ? '' : '&:hover {'}
        user-select: none;
        ${animated && `transform: translateY(-${toSpace(1)});`}
        ${animated && `box-shadow: 0 8px 8px 1px hsl(0deg 0% 0% / 40%);`}
      ${forceHover === true ? '' : '}'}
    `}

  ${({
    canFocus,
    forceFocus,
    canHover,
    forceHover,
    themeColor,
    isHolo,
    animated,
  }) =>
    (canFocus || forceFocus) &&
    `
      ${forceFocus === true ? '' : '&:focus {'}
        z-index: 1;
        ${
          animated &&
          `box-shadow: 0 0 90px 10px ${
            isHolo
              ? palette.common.white
              : themeColor
              ? toColor(themeColor)
              : palette.common.grey
          }33;`
        }
        

        ${
          (canHover || forceHover) &&
          `
            ${forceHover === true ? '' : '&:hover {'}
              box-shadow: 0 0 120px 10px ${
                isHolo
                  ? palette.common.white
                  : themeColor
                  ? toColor(themeColor)
                  : palette.common.grey
              }66;
            ${forceHover === true ? '' : '}'}
          `
        }
      ${forceFocus === true ? '' : '}'}
  `};
`;

export const NftCardContainer = styled(Stack)`
  overflow: hidden;
  border-radius: 8px;
`;

export const Thumbnail = styled(Image)`
  object-fit: cover;
`;

export const NftCardFooter = styled(Stack)`
  border-radius: 0 0 8px 8px;

  background-image: linear-gradient(
    ${palette.secondary.main} 0%,
    ${palette.secondary.main} 30%,
    ${palette.cinder.main} 100%
  );

  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: ${palette.border.main};
`;

export const ColoredDivider = styled(Box)<
  Pick<NftCardProps, 'themeColor' | 'isHolo'> & BoxProps
>`
  ${({ themeColor, isHolo }) =>
    `
      background: linear-gradient(
        to right,
        ${palette.secondary.main} 0%,
        ${
          isHolo
            ? `${palette.common.black}00`
            : themeColor
            ? toColor(themeColor)
            : palette.common.grey
        } 50%,
        ${palette.secondary.main} 100%
      )${isHolo ? `, ${palette.gradients.holo.full}` : ''};
    `}

  height: 1px;
`;

export const Divider = styled(Box)`
  border-bottom: 1px solid ${palette.common.black};
  box-shadow: 0 1px 0 0 ${palette.border.main};
`;

export const NftTagBox = styled(Box)<Pick<NftCardProps, 'isHolo'> & BoxProps>`
  border: 1px solid transparent;
  background-image: linear-gradient(
      ${palette.secondary.main},
      ${palette.secondary.main}
    ),
    ${({ isHolo }) =>
      isHolo
        ? palette.gradients.holo.full
        : `
          linear-gradient(
            ${palette.common.black},
            ${palette.common.black}
          )
      `};
  background-origin: border-box;
  background-clip: padding-box, border-box;
`;
