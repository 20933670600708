import React from 'react';

import { BoxProps } from '@/components/box/box';
import { SimpleGridBox } from '@/components/simple-grid/styles';
import { StyledValue } from '@/types/styled-props';

export interface SimpleGridProps extends BoxProps {
  columns?: StyledValue<number>;
  spacing?: StyledValue<number | string>;
  spacingX?: StyledValue<number | string>;
  spacingY?: StyledValue<number | string>;
  minChildWidth?: StyledValue<number | string>;
}

/**
 * Simple Grid helps to easily handle grid system
 */
export const SimpleGrid: React.FC<SimpleGridProps> = (props) => {
  const { columns = 1, spacing = 2, ...rest } = props;

  return <SimpleGridBox columns={columns} spacing={spacing} {...rest} />;
};
