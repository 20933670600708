import styled, { css } from 'styled-components';

import { theme } from '@/styles/theme';
import { createStyledComponent } from '@/utils/style-factory';

type IButton = {
  variant: string;
  readonly onClick?: () => void;
};

interface IGradientItem {
  main: string;
  hover: string;
  contrastText: string;
}

interface IGradients {
  primary: IGradientItem;
  secondary: IGradientItem;
  holo: IGradientItem;
}

const { border, palette, fonts, button } = theme;
const { gradients } = palette;

const Button = createStyledComponent('button');

export const buttonStyles = css`
  transition: all 300ms ease-in-out;
  outline: none;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.14rem;
  font-weight: ${fonts.weight.bold};

  border-radius: ${button.radius};
  border: ${border.size} solid ${palette.common.black};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  cursor: pointer;

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }

  &:disabled {
    background: ${palette.inputBg.main};
    color: ${palette.common.grey};
    cursor: default;
    &:hover {
      background: ${palette.inputBg.main};
      color: ${palette.common.grey};
    }
  }
`;

export const StyledButton = styled(Button)<IButton>`
  ${buttonStyles};

  background: ${({ variant }) => gradients[variant as keyof IGradients].main};
  color: ${({ variant }) =>
    gradients[variant as keyof IGradients].contrastText};
  &:hover {
    background: ${({ variant }) =>
      gradients[variant as keyof IGradients].hover};
  }
`;

export const primaryButtonStyles = css`
  background: ${gradients.primary.main};
  color: ${gradients.primary.contrastText};
  &:hover {
    background: ${gradients.primary.hover};
  }
`;

export const secondaryButtonStyles = css`
  background: ${gradients.secondary.main};
  color: ${gradients.secondary.contrastText};
  &:hover {
    background: ${gradients.secondary.hover};
  }
`;
