import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

export const StyledLoadingSpinner = styled.div`
  width: 80px;
  height: 80px;

  .wrapper {
    transform: rotate(-45deg);
    position: relative;
    height: 100%;
  }
  .main-loader .loader {
    position: relative;
    margin: 0px auto;
    width: inherit;
    height: inherit;
  }
  .main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  .circular-loader {
    animation: rotate 4s cubic-bezier(1, 1, 1, 1) infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }
  .loader-path {
    stroke-dasharray: 200 200;
    animation: dash 4s ease-in-out infinite;
    stroke-linecap: round;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 200, 200;
      stroke-dashoffset: 0;
    }

    69% {
      stroke-dasharray: 200, 200;
      stroke-dashoffset: 0;
    }

    70% {
      stroke-dasharray: 200, 200;
      stroke-dashoffset: 0;
    }

    80% {
      stroke-dasharray: 200, 200;
      stroke-dashoffset: -124;
    }

    90% {
      stroke-dasharray: 200, 200;
      stroke-dashoffset: -124;
    }

    100% {
      stroke-dasharray: 200, 0;
      stroke-dashoffset: -124;
    }
  }
`;
