import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { spacing, palette, border } = theme;

export const StyledLink = styled.a`
  color: ${palette.lightBlue.main};
  text-decoration: none;
  padding: ${spacing.base * 4}px 0;
  border-bottom: ${border.size} solid ${palette.border.main};
  &:last-child {
    border-bottom: none;
  }

  &.active {
    color: ${palette.common.white};
  }
`;
