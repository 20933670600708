import styled from 'styled-components';

import { theme } from '@/styles/theme';

type SelectProps = {
  className: string;
};

const { palette, border, spacing, breakpoints, fonts } = theme;

export const StyledSelect = styled.select<SelectProps>`
  transition: all 300ms ease-in-out;
  outline: none;
  color: ${palette.containerBg.contrastText};

  border: ${border.size} solid ${palette.common.black};
  border-radius: 4px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24);

  width: 150px;
  padding: ${spacing.base * 3}px;
  line-height: 1.6;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: ${fonts.weight.bold};

  appearance: none;
  background: ${palette.containerBg.main}
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8cGF0aCBkPSJNMTIgMC4wNzQwNTE2TDExLjk4NzkgMi4zNzEwMWUtMDdDMTEuOTM1NSAwLjA0NTIxNTcgMTEuODgxMSAwLjA4NTA4NjEgMTEuODI5OCAwLjEzNTI4NkMxMC43NTc2IDEuMTc5MTYgOS42MTgxNyAxLjk0Njc1IDguNDU3MzcgMi42MTM1OUM2Ljk3ODY4IDMuNDYyNzIgNS40Nzc4MiAzLjU1NjM2IDMuOTc4MzggMi44MjgyOUMyLjY4MzM1IDIuMjAwMzMgMS40MzExNCAxLjMyMzUzIDAuMjQ0MTY2IDAuMjEzNjExQzAuMTcwMjMxIDAuMTQ0ODk3IDAuMTA3OTggMC4wMDc0NzcxNiAtMS4wNTQwM2UtMDYgMC4wNDczNTIyQzAuMDEwNDQxNSAwLjA4MjU1MDEgMC4wMjM1OTUzIDAuMTE1MDE2IDAuMDM5MDgyOCAwLjE0MzgzMkMxLjkwNjU5IDIuNjc4MDMgMy43NzQxIDUuMjEyNiA1LjY0MTYxIDcuNzQ3ODdDNS45MDQ3MSA4LjEwNjc0IDYuMTE3MjUgOC4wNzAwNyA2LjM3NDMxIDcuNzE2ODlDOC4yNDc4NiA1LjE2MDYxIDEwLjEyMzIgMi42MTMyNCAxMiAwLjA3NDA1MTZaIiBmaWxsPSIjQUI1NEY0Ii8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZCIgeD0iMCIgeT0iMCIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEwIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIi8+CjxmZU9mZnNldCBkeT0iMiIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4yNCAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8L2RlZnM+Cjwvc3ZnPgo=')
    no-repeat;
  background-position: right ${spacing.base * 3}px top 50%;

  @media screen and (min-width: ${breakpoints.medium}) {
    width: 176px;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${palette.pink.main};
  }
`;
