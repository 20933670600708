/**
 * List of props to omit from DOM.
 */
const allPropNames = new Set([
  // margin
  'm',
  'my',
  'mx',
  'mt',
  'mb',
  'ml',
  'mr',
  // padding
  'p',
  'py',
  'px',
  'pt',
  'pb',
  'pl',
  'pr',
  // size
  'width',
  'height',
  'w',
  'h',
  'minW',
  'maxW',
  'minH',
  'maxH',
  // flex
  'flex',
  'justifySelf',
  'alignSelf',
  'order',
  // display
  'display',
  // position
  'position',
  // inset
  'inset',
  'insetX',
  'insetY',
  'top',
  'bottom',
  'left',
  'right',
  'zIndex',
  // font
  'fontSize',
  'fontWeight',
  'textAlign',
  'textTransform',
  'letterSpacing',
  'isTruncated',
  // rounded
  'rounded',
  // shadow
  'shadow',
  'textShadow',
  // border
  'borderWidth',
  'borderTopWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'borderStyle',
  'borderColor',
  // color
  'bg',
  'color',
  // Stack
  'alignItems',
  'justifyContent',
  'direction',
  // SimpleGrid
  'columns',
  'spacing',
  'spacingX',
  'spacingY',
  'minChildWidth',
  // NftCard
  'captionSlotView',
  'tagSlotView',
  'buttonSlotView',
  'subtitle',
  'ratio',
  'thumbUrl',
  'thumbRatio',
  'tag',
  'priceInEth',
  'exchangeRatio',
  'tier',
  'level',
  'themeColor',
  'isHolo',
  'isFavorite',
  'onFavorite',
  'favoriteAmount',
  'forceHover',
  'forceFocus',
  'canHover',
  'canFocus',
]);

export const shouldForwardProp = (prop: string) => !allPropNames.has(prop);
