import { FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  BinanceIcon,
  CoinSpotIcon,
  DiscordCircleIcon,
  KuCoinIcon,
  Logo,
  MediumCircleIcon,
  OkexIcon,
  OneInchIcon,
  SushiIcon,
  TelegramCircleIcon,
  TwitterCircleIcon,
  YouTubeCircleIcon,
} from '@/components/icons';
import { IlvPriceTicker } from '@/components/ilv-price-ticker';
import { SectionDivider } from '@/components/section-divider';
import { theme } from '@/styles/theme';

import {
  Container,
  ExchangeList,
  SiteMap,
  SocialIcons,
  Wrapper,
} from './styles';

const { breakpoints, palette } = theme;

export interface IFooter {
  activeItem: string;
}

interface ISiteMap {
  name: string;
  slug?: string;
  href?: string;
}

interface ISites {
  sitesMap: ISiteMap[];
  activeItem: string;
}

interface IIconMap {
  name: string;
  href: string;
  icon: JSX.Element;
}

interface IExchanges {
  isTablet: boolean;
}

interface ISocial {
  socialsMap: IIconMap[];
}

const sitesMap: ISiteMap[] = [
  {
    name: 'Staking',
    slug: 'staking',
    href: 'https://staking.illuvium.io/',
  },
  {
    name: 'Illuviary',
  },
  {
    name: 'IlluviDex',
  },
  {
    name: 'Whitepaper',
    href: 'https://docs.illuvium.io/',
  },
  {
    name: 'Governance',
    href: 'https://gov.illuvium.io/',
  },
  {
    name: 'FAQ',
    slug: 'faq',
    href: 'https://illuvium.io/faq',
  },
  {
    name: 'Contributors',
    slug: 'contributors',
    href: 'https://illuvium.io/contributors',
  },
  {
    name: 'Jobs',
    slug: 'jobs',
    href: 'https://illuvium.io/jobs',
  },
];

const siteMapFirstHalf = sitesMap.slice(0, Math.ceil(sitesMap.length / 2));
const siteMapSecondHalf = sitesMap.slice(
  Math.ceil(sitesMap.length / 2),
  sitesMap.length
);

const exchangesMap: IIconMap[] = [
  {
    name: 'Binance',
    href: 'https://www.binance.com/en/trade/ILV_USDT',
    icon: <BinanceIcon width="24px" height="24px" />,
  },
  {
    name: 'Sushi',
    href: 'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E',
    icon: <SushiIcon width="24px" height="24px" />,
  },
  {
    name: 'KuCoin',
    href: 'https://trade.kucoin.com/ILV-USDT',
    icon: <KuCoinIcon width="24px" height="24px" />,
  },
  {
    name: '1inch',
    href: 'https://app.1inch.io/#/1/swap/ETH/ILV',
    icon: <OneInchIcon width="24px" height="24px" />,
  },
  {
    name: 'OKEx',
    href: 'https://www.okex.com/markets/spot-info/ilv-usdt',
    icon: <OkexIcon width="24px" height="24px" />,
  },
  {
    name: 'CoinSpot',
    href: 'https://www.coinspot.com.au/buy/ilv',
    icon: <CoinSpotIcon width="24px" height="24px" />,
  },
];

const socialsMap: IIconMap[] = [
  {
    name: 'Discord',
    href: 'https://discord.com/invite/illuvium',
    icon: <DiscordCircleIcon />,
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/illuviumio',
    icon: <TwitterCircleIcon />,
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/c/illuvium',
    icon: <YouTubeCircleIcon />,
  },
  {
    name: 'Medium',
    href: 'https://medium.com/illuvium',
    icon: <MediumCircleIcon />,
  },
  {
    name: 'Telegram',
    href: 'https://t.me/illuvium',
    icon: <TelegramCircleIcon />,
  },
];

const Sites: FunctionComponent<ISites> = ({ sitesMap, activeItem }) => {
  return (
    <div className="site-map__item">
      {sitesMap.map(({ name, slug, href }) => {
        const isActive = activeItem === slug ? 'active' : '';
        return (
          <a
            key={name}
            className={`site-map__label ${isActive}`}
            style={!href ? { color: palette.lightBlue.main } : {}}
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            {name}
          </a>
        );
      })}
    </div>
  );
};

const Exchanges: FunctionComponent<IExchanges> = ({ isTablet }) => {
  return (
    <ExchangeList
      className={isTablet && 'exchange-list--tablet'}
      as={isTablet && 'section'}
    >
      <div className="title">Available on</div>

      <div className="exchange-list-container">
        {exchangesMap.map(({ href, icon, name }) => {
          return (
            <div className="exchange-list__item" key={href}>
              <a href={href} target="_blank" rel="noreferrer">
                {icon}

                {name}
              </a>
            </div>
          );
        })}
      </div>
    </ExchangeList>
  );
};

const Socials: FunctionComponent<ISocial> = ({ socialsMap }) => {
  return (
    <SocialIcons>
      {socialsMap.map(({ href, icon }) => {
        return (
          <a href={href} target="_blank" rel="noreferrer" key={href}>
            {icon}
          </a>
        );
      })}
    </SocialIcons>
  );
};

export const Footer: FunctionComponent<IFooter> = ({ activeItem }) => {
  const isTablet = useMediaQuery({
    query: `(min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large})`,
  });

  return (
    <Wrapper>
      <SectionDivider />

      <Container>
        <section className="row row--logo-social">
          <div className="row__item">
            <div className="logo">
              <Logo />
            </div>
          </div>

          <div className="row__item">
            <Socials socialsMap={socialsMap} />
          </div>
        </section>

        <section className="row row--site-map-price-exchanges">
          <SiteMap className="row__item row__item--site-map">
            <Sites sitesMap={siteMapFirstHalf} activeItem={activeItem} />

            <Sites sitesMap={siteMapSecondHalf} activeItem={activeItem} />
          </SiteMap>

          <div className="row__item row__item--ilv-price-exchange-list">
            <IlvPriceTicker />

            {!isTablet && <Exchanges isTablet={isTablet} />}
          </div>
        </section>

        {isTablet && <Exchanges isTablet={isTablet} />}

        <div className="copyright">
          Illuvium © {new Date().getFullYear()}, All rights reserved
        </div>
      </Container>
    </Wrapper>
  );
};
