import styled from 'styled-components';

import { Box } from '@/components/box';
import { SimpleGridProps } from '@/components/simple-grid/simple-grid';
import {
  buildResponsiveStyleString,
  groupBreakpoints,
  toSpace,
} from '@/utils/style-factory';

export const SimpleGridBox = styled(Box)<SimpleGridProps>((props) => {
  const breakpoints = groupBreakpoints<any>(props);

  return buildResponsiveStyleString(breakpoints, (breakpoint) => {
    const { columns, spacing, spacingX, spacingY, minChildWidth } = breakpoint;

    let styleStr = 'display: grid;';

    if (columns !== undefined && minChildWidth === undefined) {
      styleStr += `grid-template-columns: repeat(${columns}, minmax(0px, 1fr));`;
    } else if (minChildWidth !== undefined) {
      styleStr += `grid-template-columns: repeat(auto-fit, minmax(${toSpace(
        minChildWidth
      )}, 1fr));`;
    }

    if (spacing !== undefined) {
      styleStr += `grid-gap: ${toSpace(spacing)};`;
    }

    if (spacingX !== undefined) {
      styleStr += `column-gap: ${toSpace(spacingX)};`;
    }

    if (spacingY !== undefined) {
      styleStr += `row-gap: ${toSpace(spacingY)};`;
    }

    return styleStr;
  });
});
