import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { palette, border, spacing } = theme;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${spacing.base * 2}px;
  max-width: 58px;

  background: ${palette.containerBg.main};
  border-radius: ${border.radius};
  border: 1px solid ${palette.common.black};
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24);
`;

export const CircularContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 16px;
  height: 16px;

  background: #db4a4a;
  border-radius: 50px;

  padding: ${spacing.base * 1.2}px;

  font-weight: bold;
  font-size: 13px;
  line-height: 70%;
`;
