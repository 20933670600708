import styled, { css } from 'styled-components';

import { theme } from '@/styles/theme';

const { palette, spacing, button } = theme;
const { gradients } = palette;

export const holoBorderStyles = css`
  border: 1px solid transparent;
  border-radius: ${button.radius};
  background-image: linear-gradient(
      ${palette.secondary.main},
      ${palette.secondary.main}
    ),
    ${gradients.holo.full};
  background-origin: border-box;
  background-clip: padding-box, border-box;
`;

export const StyledHoloBadge = styled.div`
  ${holoBorderStyles};

  padding: ${spacing.base}px ${spacing.base * 4}px ${spacing.base * 1.5}px;

  text-transform: uppercase;
  letter-spacing: ${spacing.base * 1.5}px;
  font-size: 0.8rem;

  position: relative;

  > span {
    padding-left: ${spacing.base * 2}px;
  }
`;
