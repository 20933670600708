import queryString from 'query-string';

const ETH_PLATFORM_ID = 'ethereum';
const COINGECKO_BASE_API_HOSTNAME = 'https://api.coingecko.com/api/v3';

export interface IIlvPrice {
  usd: number;
  usdPrice24hChange: number;
}

export async function fetchIlvPrice(): Promise<IIlvPrice> {
  const tokenAddress = '0x767fe9edc9e0df98e07454847909b5e959d7ca0e';
  const params = {
    contract_addresses: tokenAddress,
    include_24hr_change: true,
    vs_currencies: 'usd',
  };
  const qs = queryString.stringify(params);

  try {
    const results = await fetch(
      `${COINGECKO_BASE_API_HOSTNAME}/simple/token_price/${ETH_PLATFORM_ID}?${qs}`
    );

    const json = await results.json();
    return {
      usd: json[tokenAddress].usd,
      usdPrice24hChange: json[tokenAddress].usd_24h_change,
    };
  } catch (e) {
    console.error('Failed to fetch coingecko price data');
    return { usd: 0, usdPrice24hChange: 0 };
  }
}
