import React from 'react';

import { Box } from '@/components/box';
import { AlertIcon, CheckMarkIcon } from '@/components/icons';
import { LoadingSpinner } from '@/components/loading-spinner';

import { DismissButton, ToastContainer } from './styles';

export type ToastType = 'success' | 'error' | 'loading' | 'blank' | 'custom';

export interface ToastProps {
  type?: ToastType;
  duration?: number;
  visible?: boolean;
  children?: React.ReactNode;
  onDismiss?: () => void;
}

export const ToastBar: React.FC<ToastProps> = (props) => {
  const { type, duration, visible, children, onDismiss } = props;

  const Icon = React.useCallback(() => {
    if (type === 'success') {
      return <CheckMarkIcon />;
    }

    if (type === 'error') {
      return <AlertIcon />;
    }

    if (type === 'loading') {
      return (
        <Box m={-7} style={{ transform: 'scale(0.35)' }}>
          <LoadingSpinner />
        </Box>
      );
    }

    return <React.Fragment />;
  }, [type]);

  return (
    <ToastContainer
      spacing={4}
      type={type}
      duration={duration}
      visible={visible}
      py={[3, 4, 5]}
      px={[4, 5, 6]}
      rounded={2}
      minW={80}
      maxW={120}
    >
      <Icon />

      <Box flex={1} fontWeight={'bold'}>
        {children}
      </Box>

      <DismissButton
        onClick={onDismiss}
        w={8}
        h={8}
        p={1}
        rounded={2}
        color={'common.white'}
      >
        <Box mb={0.5}>✗</Box>
      </DismissButton>
    </ToastContainer>
  );
};
