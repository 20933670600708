import styled from 'styled-components';

import { theme } from '@/styles/theme';

const { breakpoints, spacing, palette, fonts } = theme;

export const IlvPrice = styled.div`
  margin: ${spacing.base * 8}px 0;

  display: flex;

  .title {
    font-weight: ${fonts.weight.bold};
    color: ${palette.lightBlue.main};
    margin-bottom: ${spacing.base}px;
  }

  .icon {
    margin-right: ${spacing.base * 2}px;
  }

  .content__price-usd {
    color: ${palette.common.white};
    font-size: 1.3rem;
  }

  .content__price-change {
    svg {
      margin-right: ${spacing.base}px;
    }
  }

  .price-change--is-up {
    color: ${palette.brightGreen.main};
  }

  .price-change--is-down {
    color: ${palette.common.red};
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    justify-content: center;
    margin: 0;

    .icon {
      position: relative;
      top: ${spacing.base}px;
    }

    .content__price-usd {
      color: ${palette.common.white};
      margin: ${spacing.base}px 0 ${spacing.base * 2}px;
    }
  }

  @media screen and (min-width: ${breakpoints.large}) {
    .title {
      margin-bottom: ${spacing.base * 2}px;
    }

    .icon {
      top: ${spacing.base * 3}px;
      margin-right: ${spacing.base * 4}px;
      width: 90%;
    }

    .content__price-usd {
      color: ${palette.common.white};
      margin: ${spacing.base * 3.5}px 0 ${spacing.base * 2}px;
    }
  }
`;
