import styled, { keyframes } from 'styled-components';

type IWrapper = {
  shouldShow: boolean;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    top: -20px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    top: 0px;
  }
  100% {
    opacity: 0;
    top: -100px;
  }
`;

export const Wrapper = styled.div<IWrapper>`
  position: relative;
  animation: ${({ shouldShow }) => (shouldShow ? fadeIn : fadeOut)} 0.5s;
`;
