import styled from 'styled-components';

interface DotProps {
  isWhite: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 4px;
  height: 4px;

  background-color: ${({ isWhite }) =>
    isWhite ? 'white' : 'rgba(255, 255, 255, 0.25)'};

  border-radius: 1px;

  box-shadow: 0 0 4px 2px #492968;
`;
