import styled, { css } from 'styled-components';

import { Box } from '@/components/box';
import {
  secondaryButtonStyles,
  StyledButton,
} from '@/components/button/styles';
import { theme } from '@/styles/theme';

const { palette, border, spacing } = theme;

export const cardStyles = css`
  background: ${palette.containerBg.main};
  border-radius: ${border.radius};
  border: 1px solid ${palette.common.black};
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24);
`;

export const CardBox = styled(Box)`
  ${cardStyles}
`;

export const CardButton = styled(StyledButton)`
  ${secondaryButtonStyles};

  align-items: center;
  font-size: 0.8rem;
  justify-content: space-evenly;

  svg {
    position: relative;
    top: 1px;
  }

  &:not(:last-child) {
    margin-right: ${spacing.base * 4}px;
  }
`;
